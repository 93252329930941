/*------------------------
    Banner Styles  
-------------------------*/
// Slider Dot 
.slider-dot {
    &.rn-slick-dot {
        .slick-dots {
            bottom: 60px;
        }
    }
}

.height-850 {
    height: 850px;
    display: flex;
    align-items: center;

    @media #{$md-layout} {
        height: auto;
        padding: 150px 0;
    }

    @media #{$sm-layout} {
        height: auto;
        padding: 100px 0;
    }
}

.height-650 {
    height: 650px;
    display: flex;
    align-items: center;

    @media #{$md-layout} {
        height: auto;
        padding: 150px 0;
    }

    @media #{$sm-layout} {
        height: auto;
        padding: 100px 0;
    }
}


.height-750 {
    height: 750px;
    display: flex;
    align-items: center;

    @media #{$md-layout} {
        height: auto;
        padding: 150px 0;
    }

    @media #{$sm-layout} {
        height: auto;
        padding: 100px 0;
    }
}

.height-950 {
    height: 950px;
    display: flex;
    align-items: center;

    @media #{$md-layout} {
        height: auto;
        padding: 150px 0;
    }

    @media #{$sm-layout} {
        height: auto;
        padding: 150px 0;
    }
}

.display-one {
    font-size: 75px;
    @media #{$lg-layout} {
        font-size: 70px;
    }
    @media #{$md-layout} {
        font-size: 65px;
    }
    @media #{$sm-layout} {
        font-size: 50px;
    }
    @media #{$large-mobile} {
        font-size: 46px;
    }
}

.display-two {
    font-size: 85px;
    @media #{$lg-layout} {
        font-size: 70px;
    }
    @media #{$md-layout} {
        font-size: 65px;
    }

    @media #{$sm-layout} {
        font-size: 65px;
    }

    @media #{$large-mobile} {
        font-size: 46px;
    }
}



.bg-overlay {
    position: relative;

    &::before {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 1;
        top: 0;
        left: 0;
        background-color: transparent;
        background-image: linear-gradient(90deg, #060606 0%, #06060600 90%);
        content: "";
    }
}



.bg-overlay-solid {
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        height: 100%;
        width: 40%;
        opacity: 1;
        top: 0;
        left: 0;
        content: "";
        background: var(--color-dark);
        z-index: -1;
    }

    &::after {
        position: absolute;
        width: 500px;
        height: 500px;
        border-radius: 1000px;
        opacity: 0.5;
        filter: blur(100px);
        left: auto;
        top: -250px;
        left: -250px;
        bottom: auto;
        background-image: linear-gradient(45deg, var(--color-secondary), var(--color-tertiary));
        content: "";
        z-index: -1;
    }
}


.frame-image {
    padding: 20px;
    position: relative;
    right: 0;
    border-radius: 10px;
    transition: var(--transition);
    height: 100%;
    background-color: var(--color-blackest);

    img {
        width: 100%;
    }

    &:hover {
        transform: translateY(-5px);
    }
}

.slider-area {
    .inner {
        a,
        button {
            &.btn-default {
                &.btn-border {
                    border-color: var(--color-border);
                    color: var(--color-white);
                }
            }
        }
    }
}

/*-----------------------
    Slider Style One  
-------------------------*/

.slider-style-1 {
    .inner {
        .subtitle {
            font-family: var(--font-primary);
            font-size: 18px;
            letter-spacing: 0.5px;
            display: block;
            margin-bottom: 18px;
            color: var(--color-body);

            @media #{$md-layout} {
                font-size: 16px;
                margin-bottom: 10px;
            }

            @media #{$sm-layout} {
                font-size: 16px;
                margin-bottom: 10px;
            }

            @media #{$large-mobile} {
                font-size: 14px;
            }
        }

        .title {
            font-family: var(--font-primary);
            margin-bottom: 25px;
            display: inline-block;

            span {
                display: inline-block;

                label {
                    margin-bottom: 0;
                }
            }
        }

        .description {
            font-family: var(--font-primary);
            padding: 0 22%;
            font-size: 25px;
            line-height: 1.7;
            margin-bottom: 40px;
            color: var(--color-body);

            @media #{$lg-layout} {
                padding: 0 14%;
                font-size: 22px;
                margin-bottom: 35px;
            }

            @media #{$md-layout} {
                padding: 0 1%;
                font-size: 20px;
                margin-bottom: 30px;
            }

            @media #{$sm-layout} {
                padding: 0 1%;
                font-size: 20px;
                margin-bottom: 30px;
            }

            @media #{$large-mobile} {
                font-size: 18px;
                margin-bottom: 25px;
            }
        }

        .button-group {}

        &.text-start {
            .description {
                padding: 0;
            }
        }


    }

    &.variation-default {
        .inner {
            padding-top: 80px;
        }
    }

    &.variation-2 {
        position: relative;
        .thumbnail {
            img {
                transform: scale(1.1);
            }
        }
    }

}

// Slider Style Two 

.slider-style-2 {
    padding-top: 130px;

    @media #{$md-layout} {
        padding-top: 150px;
    }

    @media #{$sm-layout} {
        padding-top: 150px;
    }
    .inner {
        .subtitle {
            font-weight: 700;
            letter-spacing: 0.5px;
            line-height: 18px;
            font-size: 14px;
            border-radius: 4px;
            margin-bottom: 12px;
            display: inline-block;
            padding: 7px 14px;
            background-color: var(--color-blackest);
            box-shadow: var(--shadow-lighter);
        }

        .title {
            font-family: var(--font-primary);
            margin-bottom: 25px;
            display: block;
            color: var(--color-white);
            &.display-one {
                @media #{$sm-layout} {
                    font-size: 40px;
                }
            }
        }

        .list-icon {
            li {
                color: var(--color-body);
            }
        }
    }

    &.variation-2 {
        padding-top: 79px;
        @media #{$md-layout} {
            padding-top: 170px;
        }
        @media #{$sm-layout} {
            padding-top: 170px;
        }

        .inner {
            .title {
                font-weight: 500;
                font-size: 56px;
                margin-top: 0;
                @media #{$md-layout} {
                    font-size: 54px;
                }
                @media #{$sm-layout} {
                    font-size: 54px;
                }
                @media #{$large-mobile} {
                    font-size: 40px;
                }
            }

            .tag-title {
                color: var(--color-secondary);
                margin: 0;
                letter-spacing: 1.5px;
                padding-left: 129px;
                position: relative;
                margin-top: 50px;

                &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    width: 100px;
                    height: 2px;
                    background: var(--color-secondary);
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }

    &.variation-3 {
        padding-top: 80px;
        @media #{$md-layout} {
            padding-top: 180px;
        }

        @media #{$sm-layout} {
            padding-top: 180px;
        }

        .inner {
            .title {
                font-family: var(--font-primary);
                margin-bottom: 25px;
                display: block;
                font-size: 80px;
                @media #{$md-layout} {
                    font-size: 60px;
                }
                @media #{$sm-layout} {
                    font-size: 50px;
                    br {
                        display: none;
                    }
                }
            }

            .description {
                font-size: 20px;
                line-height: 37px;
                margin-bottom: 0;
                @media #{$sm-layout} {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
        }
    }
}

// Slider Style Three 

.slider-style-3 {
    @media #{$md-layout} {
        padding: 100px 0;
    }

    @media #{$sm-layout} {
        padding: 100px 0;
    }

    .inner {
        .title {
            font-family: var(--font-primary);
            margin-bottom: 25px;
            display: block;
            font-size: 75px;
            line-height: 1.1;

            @media #{$lg-layout} {
                font-size: 100px;
            }

            @media #{$md-layout} {
                font-size: 80px;
            }

            @media #{$sm-layout} {
                margin-bottom: 20px;
                font-size: 50px;
            }
        }

        .titleSmall {
            font-size: 75px;

            
            @media #{$sm-layout} {
                margin-bottom: 20px;
                font-size: 50px;
            }
        }

        .description {
            font-size: 22px;
            line-height: 43px;

            @media #{$md-layout} {
                font-size: 20px;
                line-height: 38px;
            }

            @media #{$sm-layout} {
                font-size: 18px;
                line-height: 30px;
            }
        }
    }

    .thumbnail {
        position: relative;
        z-index: 2;

        &::after {
            position: absolute;
            z-index: -1;
            width: 100%;
            height: calc(100% - 130px);
            content: "";
            left: 50%;
            bottom: 0;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            background: var(--color-blackest);
            box-shadow: var(--shadow-1);
            border-radius: 6px;
        }
    }
}


// Slider Style 4 
.slider-style-4 {
    .inner {
        padding-top: 80px;

        @media #{$sm-layout} {
            padding-top: 50px;
        }
        >* {
            animation-name: fadeOutUp;
            animation-fill-mode: both;
            animation-duration: 1s;
        }
        .title {
            font-family: var(--font-primary);
            margin-bottom: 25px;
            display: block;
            font-size: 75px;
            line-height: 1.1;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            color: var(--color-white);

            @media #{$lg-layout} {
                font-size: 100px;
            }

            @media #{$md-layout} {
                font-size: 100px;
            }

            @media #{$sm-layout} {
                font-size: 60px;
            }

            @media #{$large-mobile} {
                font-size: 55px;
            }
        }

        .description {
            font-size: 22px;
            line-height: 43px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            color: var(--color-body);
            @media #{$sm-layout} {
                font-size: 20px;
                line-height: 35px;
            }
        }

        .button-group {
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
    }
    &.variation-2 {
        .inner {
            .title {
                font-size: 70px;
                @media #{$sm-layout} {
                    font-size: 42px;
                }
            }
        }
        .bg-overlay {
            &::before {
                background: #000000;
                content: "";
                opacity: 0.5;
                background-image: none;
            }
        }
    }
    &.variation-3 {
        .inner {
            .title {
                font-size: 70px;
            }
        }
        .bg-overlay {
            &::before {
                background: #000000;
                content: "";
                opacity: 0.5;
                background-image: none;
            }
        }
    }
}

// Slider Style 5
.slider-style-5 {
    .inner {
        .subtitle {
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 0.5px;
            position: relative;
            padding-right: 20px;
            display: inline-block;
            color: var(--color-white);

            &::after {
                position: absolute;
                content: "";
                width: 120px;
                height: 2px;
                background: var(--color-pink);
                left: 100%;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .title {
            font-family: var(--font-primary);
            margin-bottom: 25px;
            display: block;
            font-size: 64px;
            line-height: 1.4;
            color: var(--color-white);

            @media #{$sm-layout} {
                font-size: 40px;
            }
        }
    }
}



// Slider Style 5
.slider-style-6 {
    background-color: var(--color-blackest);

    @media #{$md-layout} {
        overflow: hidden;
        padding: 100px 0;
        padding-top: 170px;
    }

    &.shape-right {
        position: relative;
        z-index: 2;
        overflow: hidden;
        .shape-image {
            position: absolute;
            right: 0;
            top: 0;
            opacity: 0.5;
            width: auto;
            @media #{$md-layout} {
                display: none;
            }
            @media #{$sm-layout} {
                display: none;
            }
        }
    }

    &.shape-left {
        position: relative;
        .inner {
            position: relative;
            z-index: 2;
        }
        .shape-image {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0.5;
            transform: rotate(180deg);
            height: 100%;
            bottom: 0;
            width: auto;
            @media #{$md-layout} {
                display: none;
            }

            @media #{$sm-layout} {
                display: none;
            }
        }
    }

    &.inheader-not-transparent {
        @media #{$md-layout} {
            padding-top: 100px;
        }
    }

    .inner {
        .title {
            font-family: var(--font-primary);
            margin-bottom: 25px;
            display: block;
            font-size: 80px;
            line-height: 1.1;
            
            @media #{$lg-layout} {
                font-size: 62px;
            }
            
            @media #{$md-layout} {
                font-size: 60px;
            }

            @media #{$sm-layout} {
                font-size: 45px;
            }
            br {
                @media #{$sm-layout} {
                    display: none;
                }
            }
        }

        .description {
            font-size: 22px;
            line-height: 43px;

            @media #{$md-layout} {
                font-size: 20px;
                line-height: 38px;
            }

            @media #{$sm-layout} {
                font-size: 18px;
                line-height: 30px;
            }
        }

        a {
            &.btn-default {
                &.btn-border {
                    border-color: var(--color-border);
                    color: var(--color-white);
                }
            }
        }
    }
}

.slider-perosonal-portfolio {
    &.height-750 {
        @media #{$md-layout} {
            padding-top: 180px;
        }
        @media #{$sm-layout} {
            padding-top: 180px;
        }
    }
    .inner {
        margin-top: -100px;
        @media #{$md-layout} {
            margin-top: 0;
        }
        @media #{$sm-layout} {
            margin-top: 0;
        }
        .title {
            font-size: 100px;
            color: var(--color-white);
            @media #{$md-layout} {
                font-size: 58px;
            }
            @media #{$sm-layout} {
                font-size: 46px;
            }
        }

        a,
        button {
            &.btn-default {
                &.btn-border {
                    border-color: var(--color-border);
                    color: var(--color-white);
                }
            }
        }
    }
}

// Slider Style 7
.slider-style-7 {
    .inner {
        .title {
            font-size: 100px;
            @media #{$lg-layout} {
                font-size: 60px;
            }
            @media #{$md-layout} {
                font-size: 50px;
            }
            @media #{$sm-layout} {
                br {
                    display: none;
                }
                font-size: 32px;
            }
        }
        
    }
}




// Slider Style 8

.slider-style-8 {
    justify-content: center;
    .inner {
        .title {
            font-size: 100px;
            line-height: 1.1;
            @media #{$md-layout} {
                font-size: 60px;
            }
            @media #{$sm-layout} {
                font-size: 46px;
            }
        }
        .description {
            font-size: 22px;
            line-height: 43px;
            @media #{$sm-layout} {
                font-size: 20px;
                line-height: 38px;
            }
            br {
                @media #{$md-layout} {
                    display: none;
                }
                @media #{$sm-layout} {
                    display: none;
                }
            }
        }
    }
}
// Slider Animation 
.slider-style-4 {
    .slick-active {
        .inner {
            >* {
                animation-name: fadeInUp;
            }

            .title {
                animation-delay: 0.5s;
            }

            .description {
                animation-delay: 1s;
            }

            .button-group {
                animation-delay: 1.2s;
            }
        }
    }
}



// @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
//     .theme-gradient {
//         color:#f9004d;
//         background: none !important;
//     }
// }



